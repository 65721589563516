import { createRouter, createWebHistory } from 'vue-router'
import { useUserStore } from '@/stores/user'
import { storeToRefs } from 'pinia'

import HomePage from '@/pages/HomePage.vue'
import PageNotFound from '@/templates/PageNotFound.vue'
import TableAreas from '@/pages/TableAreas.vue'
import TableRates from '@/pages/TableRates.vue'
import TableDiscounts from '@/pages/TableDiscounts.vue'
import ProfileSettings from '@/pages/ProfileSettings.vue'
import SignIn from '@/pages/SignIn.vue'
import ListDiscounts from '@/pages/ListDiscounts.vue'
import TableUsers from '@/pages/TableUsers.vue'
import ProfileCreate from '@/pages/ProfileCreate.vue'
import AuditLog from '@/pages/AuditLog.vue'
import RideHistoryRequests from '@/pages/RideHistoryRequests.vue'
import TableRoles from '@/pages/TableRoles.vue'

const beforeEnterAuth = (to) => {
  const userStore = useUserStore()
  const { isLoggedIn /*user, permissions*/ } = storeToRefs(userStore)

  if (isLoggedIn.value) {
    if (to.meta.permission) {
      if (!userStore.checkPermission(to.meta.permission)) {
        //TODO: Make route - 403 page
        router.push({ name: '404' })
      }
    }
  }
  if (to.meta.requiresAuth && !isLoggedIn.value) {
    router.push({ name: 'signin' })
  }
}

const routes = [
  {
    path: '/',
    name: 'home',
    props: true,
    component: HomePage,
    meta: {
      title: 'Home - ' + import.meta.env.VITE_PROJECT_TITLE,
      requiresAuth: true,
      permission: 'home.view'
    },
    beforeEnter: [beforeEnterAuth]
  },
  {
    path: '/signin',
    name: 'signin',
    props: true,
    component: SignIn,
    meta: { title: 'Login Dashboard - ' + import.meta.env.VITE_PROJECT_TITLE }
  },
  {
    path: '/areas',
    name: 'areas',
    props: true,
    component: TableAreas,
    meta: {
      title: 'Areas - ' + import.meta.env.VITE_PROJECT_TITLE,
      requiresAuth: true,
      permission: 'areas.view'
    },
    beforeEnter: [beforeEnterAuth]
  },
  {
    path: '/rates',
    name: 'rates',
    props: true,
    component: TableRates,
    meta: {
      title: 'Rates - ' + import.meta.env.VITE_PROJECT_TITLE,
      requiresAuth: true,
      permission: 'rates.view'
    },
    beforeEnter: [beforeEnterAuth]
  },
  {
    path: '/discounts',
    name: 'discounts',
    props: true,
    component: TableDiscounts,
    meta: {
      title: 'Coefficients - ' + import.meta.env.VITE_PROJECT_TITLE,
      requiresAuth: true,
      permission: 'discounts.view'
    },
    beforeEnter: [beforeEnterAuth]
  },
  {
    path: '/ride-histories/requests',
    name: 'ride-histories-requests',
    props: true,
    component: RideHistoryRequests,
    meta: {
      title: 'Rides Histories Requests - ' + import.meta.env.VITE_PROJECT_TITLE,
      requiresAuth: true,
      permissions: 'ride-history-requests.view'
    },
    beforeEnter: [beforeEnterAuth]
  },
  {
    path: '/calendar',
    name: 'calendar',
    props: true,
    component: ListDiscounts,
    meta: {
      title: 'Calendar - ' + import.meta.env.VITE_PROJECT_TITLE,
      requiresAuth: true,
      permission: 'callendar.view'
    },
    beforeEnter: [beforeEnterAuth]
  },
  {
    path: '/profile/create',
    name: 'profile_create',
    props: true,
    component: ProfileCreate,
    meta: { title: 'Profile Create - ' + import.meta.env.VITE_PROJECT_TITLE, requiresAuth: true },
    beforeEnter: [beforeEnterAuth]
  },
  {
    path: '/profile/:id',
    name: 'profile',
    props: true,
    component: ProfileSettings,
    meta: { title: 'Profile Settings - ' + import.meta.env.VITE_PROJECT_TITLE, requiresAuth: true },
    beforeEnter: [beforeEnterAuth]
  },
  {
    path: '/users',
    name: 'users',
    props: true,
    component: TableUsers,
    meta: {
      title: 'Users - ' + import.meta.env.VITE_PROJECT_TITLE,
      requiresAuth: true,
      permission: 'users.view'
    },
    beforeEnter: [beforeEnterAuth]
  },
  {
    path: '/roles',
    name: 'roles',
    props: true,
    component: TableRoles,
    meta: {
      title: 'Roles - ' + import.meta.env.VITE_PROJECT_TITLE,
      requiresAuth: true,
      permission: 'users.create'
    },
    beforeEnter: [beforeEnterAuth]
  },
  {
    path: '/audit',
    name: 'audit',
    props: true,
    component: AuditLog,
    meta: {
      title: 'Audit Log - ' + import.meta.env.VITE_PROJECT_TITLE,
      requiresAuth: true,
      permission: 'audit.view'
    },
    beforeEnter: [beforeEnterAuth]
  },
  {
    path: '/:pathMatch(.*)*',
    name: '404',
    component: PageNotFound,
    meta: { title: 'Page Not Found - ' + import.meta.env.VITE_PROJECT_TITLE, requiresAuth: true },
    beforeEnter: [beforeEnterAuth]
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach(async (to /*, from*/) => {
  document.title = to.meta.title
})
export default router
