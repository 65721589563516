<template>
  <section>
    <table class="grid w-full gap-y-4 text-center text-lg/[1.38] lg:text-left">
      <thead>
        <tr
          class="grid grid-cols-3 items-center border-b border-silver-fog pb-1.5 text-ghost-gray lg:max-xl:gap-x-32"
        >
          <th class="font-normal">Car class</th>
          <th class="font-normal">Price</th>
          <th class="font-normal">Prog</th>
        </tr>
      </thead>
      <tbody class="grid gap-y-2">
        <tr
          v-for="car in calcData"
          :key="car.class_id"
          class="grid cursor-pointer grid-cols-3 items-center rounded-md px-3 py-2.5 duration-300 hover:bg-midnight-slate/10 dark:hover:bg-midnight-slate lg:max-xl:gap-x-32"
        >
          <td>{{ car.class_full_name }}</td>
          <td>{{ car.price }} €</td>
          <td>
            Prog {{ renderCarDiscount(car) }}
            <span> Night Surcharge: {{ car.nightSurge }}%</span>
            <span v-if="checkArea(car)">, Area: {{ checkArea(car) }}</span>
          </td>
        </tr>
      </tbody>
    </table>
  </section>
</template>

<script setup>
import { useCalcStore } from '@/stores/calc'
import { collect } from 'collect.js'
import moment from 'moment'
import { storeToRefs } from 'pinia'

const calcStore = useCalcStore()

const { calcData } = storeToRefs(calcStore)

const renderCarDiscount = (car) => {
  if (!car.discount) return 'No Prog'

  let carDiscountString = car?.discount?.id

  let symbol = car.discount.plus_minus === 'plus' ? '+' : '-'

  carDiscountString += ': '
  carDiscountString += collect(car.discount.areas).pluck('area_short_name').join(', ') + ' '
  carDiscountString += symbol
  carDiscountString += car.discount.discount_value + (car.discount.type === 1 ? '€' : '%') + ' '

  carDiscountString += ' - '
  carDiscountString += moment(car.discount.start_date).format('D.M.Y h:mm a') + ' - '
  carDiscountString += moment(car.discount.end_date).format('D.M.Y h:mm a,')

  return carDiscountString
}

const checkArea = (car) => {
  if (car?.discount?.areas.length) {
    return car.discount.areas[0].area_name
  } else {
    return false
  }
}
</script>

<style scoped></style>
