<template>
  <main
    :class="menuCollapsed ? 'md:pl-[140px]' : 'md:pl-[284px]'"
    class="pl-6 pr-6 pt-[70px] pb-[140px] text-sm/[1.42] lg:text-lg/[1.38] duration-300"
  >
    <section class="mb-9 flex items-start justify-between">
      <h1 class="text-[40px] font-bold leading-[1.1] lg:text-[80px]">
        Coefficients
        <button
          v-if="checkPermission('discounts.create')"
          @click="addDiscount"
          class="group rounded-md bg-[#eaeaeb] dark:bg-shadowy-steel p-2.5 lg:mb-3.5"
        >
          <img
            class="group-hover:scale-105 duration-300 mx-auto"
            src="@/assets/images/plusSquare.svg"
            alt="copyIcon"
          />
        </button>
      </h1>
      <label
        class="flex cursor-pointer items-center gap-x-3 rounded-md bg-[#eaeaeb] dark:bg-shadowy-steel px-3 py-2.5"
      >
        <span>Show archived</span>
        <input
          :checked="!filters.status.value"
          @change="changeShowArchived"
          class="h-6 w-6 cursor-pointer appearance-none rounded-[5px] border border-lush-green bg-center bg-no-repeat duration-300 checked:bg-[url(@/assets/images/tick.svg)] checked:!bg-lush-green checked:bg-blend-difference focus:ring-lush-green focus:bg-blend-difference"
          type="checkbox"
        />
      </label>
    </section>
    <DiscountModals
      :isShowModal="isShowModal"
      :modalData="modalData"
      @action="modalAction"
      @close="closeModal"
    />
    <section class="mb-[30px] overflow-x-scroll">
      <!-- Adapt table for mobile devices -->
      <div class="overflow-hidden" id="headerdiv">
        <table id="headertable" class="w-full">
          <thead class="relative">
            <!-- Overlay -->
            <tr
              :class="{ 'absolute w-full h-full z-10 rounded-t-md bg-gray-600/20': requestTime }"
            ></tr>
            <tr
              :class="
                !filters.status.value
                  ? 'grid-cols-[116px_repeat(2,_246px)_minmax(100px,_1fr)_64px_116px_78px_repeat(2,_minmax(124px,_1fr))_48px_repeat(3,_minmax(48px,_96px))]'
                  : 'grid-cols-[116px_repeat(2,_246px)_minmax(100px,_1fr)_64px_116px_78px_repeat(2,_minmax(124px,_1fr))_48px_repeat(3,_minmax(48px,_96px))]'
              "
              class="grid items-center gap-x-1 border-b border-silver-fog text-ghost-gray"
            >
              <th @click="setOrder('id')" class="text-left font-normal cursor-pointer">
                Prog ID
                <span v-if="order.active_order === 'id'">
                  <sup v-if="order.id === 'asc'">↑</sup>
                  <sup v-if="order.id === 'desc'">↓</sup>
                </span>
              </th>
              <th @click="setOrder('start_date')" class="font-normal cursor-pointer">
                Start Date
                <span v-if="order.active_order === 'start_date'">
                  <sup v-if="order.start_date === 'asc'">↑</sup>
                  <sup v-if="order.start_date === 'desc'">↓</sup>
                </span>
              </th>
              <th @click="setOrder('end_date')" class="font-normal cursor-pointer">
                End Date
                <span v-if="order.active_order === 'end_date'">
                  <sup v-if="order.end_date === 'asc'">↑</sup>
                  <sup v-if="order.end_date === 'desc'">↓</sup>
                </span>
              </th>
              <th class="font-normal">Description</th>
              <th @click="setOrder('discount_value')" class="col-span-3 font-normal cursor-pointer">
                Coefficient
                <span v-if="order.active_order === 'discount_value'">
                  <sup v-if="order.discount_value === 'asc'">↑</sup>
                  <sup v-if="order.discount_value === 'desc'">↓</sup>
                </span>
              </th>
              <th class="font-normal">Area</th>
              <th class="font-normal">Cars</th>
              <th class="font-normal"></th>
              <th class="font-normal col-span-3">Option</th>
            </tr>
          </thead>
        </table>
      </div>
      <div
        class="overflow-y-scroll max-h-[100vh]"
        onscroll="document.getElementById('headerdiv').scrollLeft = this.scrollLeft;"
      >
        <table id="bodytable" class="w-full text-center">
          <tbody class="flex flex-col divide-y divide-silver-fog relative pt-4">
            <!-- Overlay -->
            <tr
              :class="{ 'absolute w-full h-full z-10 rounded-b-md bg-gray-600/20': requestTime }"
            ></tr>
            <tr
              v-if="discounts"
              class="grid grid-cols-[116px_repeat(2,_246px)_minmax(100px,_1fr)_64px_116px_78px_repeat(2,_minmax(124px,_1fr))_48px_repeat(3,_minmax(48px,_96px))] items-center gap-x-1 text-ghost-gray py-1 !border-t-0"
            >
              <td>
                <input
                  v-model="filters.progid.value"
                  @beforeinput="checkNumber"
                  class="w-full rounded-md bg-[#eaeaeb] dark:bg-shadowy-steel focus:border-transparent focus:ring-0 focus:outline-lush-green focus:outline-offset-0"
                  placeholder="Prog ID"
                  type="number"
                  min="0"
                />
              </td>
              <td>
                <InputMask
                  @update:modelValue="(value) => handleInputMask('start_date', value)"
                  class="w-full bg-[url(@/assets/images/calendar.svg)] bg-[11px_center] bg-[length:16px] bg-no-repeat bg-[#eaeaeb] dark:bg-[#212121] border border-shadowy-steel rounded-[4px] py-[10px] pr-[12px] pl-[35px] hover:border-[#aaaeb7] duration-200 focus-visible:outline-0 focus:shadow-none focus:outline-0 focus:ring-0 focus:ring-offset-0 focus:border-[#aaaeb7] text-base text-black dark:text-white"
                  mask="99/99/9999 99:99"
                  placeholder="__/__/____ __:__"
                />
              </td>
              <td>
                <InputMask
                  @update:modelValue="(value) => handleInputMask('end_date', value)"
                  class="w-full bg-[url(@/assets/images/calendar.svg)] bg-[11px_center] bg-[length:16px] bg-no-repeat bg-[#eaeaeb] dark:bg-[#212121] border border-shadowy-steel rounded-[4px] py-[10px] pr-[12px] pl-[35px] hover:border-[#aaaeb7] duration-200 focus-visible:outline-0 focus:shadow-none focus:outline-0 focus:ring-0 focus:ring-offset-0 focus:border-[#aaaeb7] text-base text-black dark:text-white"
                  mask="99/99/9999 99:99"
                  placeholder="__/__/____ __:__"
                />
              </td>
              <td>
                <input
                  v-model="filters.description.value"
                  class="w-full rounded-md bg-[#eaeaeb] dark:bg-shadowy-steel focus:border-transparent focus:ring-0 focus:outline-lush-green focus:outline-offset-0"
                  placeholder="Description"
                  type="text"
                />
              </td>
              <td>
                <select
                  v-model="filters.plus_minus.value"
                  class="w-full cursor-pointer appearance-none rounded-md bg-[#eaeaeb] dark:bg-shadowy-steel bg-[url(@/assets/images/arrow.svg)] bg-[right_12px_center] bg-no-repeat py-2.5 pl-3 pr-9 focus:border-transparent focus:ring-0 focus:outline-lush-green focus:outline-offset-0"
                >
                  <option value="plus">+</option>
                  <option value="minus">-</option>
                </select>
              </td>
              <td class="col-span-2">
                <input
                  v-model="filters.discount_value.value"
                  class="w-full rounded-md bg-[#eaeaeb] dark:bg-shadowy-steel focus:border-transparent focus:ring-0 focus:outline-lush-green focus:outline-offset-0"
                  placeholder="Coefficient"
                  type="text"
                />
              </td>
              <td>
                <div class="multiselect relative uppercase">
                  <div
                    @click="showMultiselectOptions"
                    class="multiselectHeading cursor-pointer overflow-x-clip whitespace-nowrap rounded-md bg-[#eaeaeb] dark:bg-shadowy-steel py-2.5 pl-3 pr-9"
                  >
                    <span>{{ selectedAreas(filters.areas.areas_array) }}</span>
                    <div
                      class="absolute right-0 top-[calc(50%-12px)] my-auto dark:bg-shadowy-steel px-3"
                    >
                      <img src="@/assets/images/arrow.svg" alt="arrowIcon" />
                    </div>
                  </div>
                  <div
                    class="options absolute left-0 top-[calc(100%_+_12px)] z-10 w-max min-w-full divide-y divide-midnight-ink rounded-md bg-[#eaeaeb] dark:bg-shadowy-steel hidden"
                  >
                    <label
                      v-for="(area, index) in areas"
                      :key="index"
                      class="flex cursor-pointer items-center gap-x-3 p-3"
                    >
                      <input
                        :id="'filter-discount-area' + area.id"
                        @change="changeDiscountAreas(area, filters.areas)"
                        :checked="areaChecked(filters.areas.areas_array, area.id)"
                        class="h-6 w-6 cursor-pointer appearance-none rounded-[5px] border border-lush-green bg-center bg-no-repeat checked:bg-[url(@/assets/images/tick.svg)] checked:bg-lush-green checked:bg-blend-difference focus:ring-lush-green focus:bg-blend-difference"
                        type="checkbox"
                      />
                      <span>{{ area.area_name }}</span>
                    </label>
                  </div>
                </div>
              </td>
              <td>
                <div class="multiselect relative uppercase">
                  <div
                    @click="showMultiselectOptions"
                    class="multiselectHeading cursor-pointer overflow-x-clip whitespace-nowrap rounded-md bg-[#eaeaeb] dark:bg-shadowy-steel py-2.5 pl-3 pr-9"
                  >
                    <span>{{ selectedCars(filters.cars.cars_array) }}</span>
                    <div
                      class="absolute right-0 top-[calc(50%-12px)] my-auto bg-[#eaeaeb] dark:bg-shadowy-steel px-3"
                    >
                      <img src="@/assets/images/arrow.svg" alt="arrowIcon" />
                    </div>
                  </div>
                  <div
                    class="options absolute left-0 top-[calc(100%_+_12px)] z-10 w-max min-w-full divide-y divide-midnight-ink rounded-md bg-[#eaeaeb] dark:bg-shadowy-steel hidden"
                  >
                    <label
                      :key="index"
                      v-for="(car, index) in cars"
                      class="flex cursor-pointer items-center gap-x-3 p-3"
                    >
                      <input
                        :id="'filter-discount-car' + car.class_id"
                        @change="changeDiscountCars(car, filters.cars)"
                        :checked="carChecked(filters.cars.cars_array, car.class_id)"
                        class="h-6 w-6 cursor-pointer appearance-none rounded-[5px] border border-lush-green bg-center bg-no-repeat checked:bg-[url(@/assets/images/tick.svg)] checked:bg-lush-green checked:bg-blend-difference focus:ring-lush-green focus:bg-blend-difference"
                        type="checkbox"
                      />
                      <span>{{ car.class_name }}</span>
                    </label>
                  </div>
                </div>
              </td>
              <td>
                <div
                  class="rounded-md bg-[#eaeaeb] dark:bg-shadowy-steel p-2.5 flex justify-center items-center cursor-pointer"
                >
                  <input
                    v-model="filters.priority.value"
                    class="h-6 w-6 appearance-none rounded-[5px] border border-lush-green bg-center bg-no-repeat duration-300 checked:bg-[url(@/assets/images/tick.svg)] checked:bg-lush-green checked:bg-blend-difference focus:ring-lush-green focus:bg-blend-difference"
                    type="checkbox"
                  />
                </div>
              </td>
              <td class="col-span-3 grid grid-cols-2 gap-x-1">
                <button
                  @click="filterDiscounts"
                  class="group w-full rounded-md bg-[#eaeaeb] dark:bg-shadowy-steel px-3 py-2.5"
                >
                  <img
                    class="group-hover:scale-105 duration-300 mx-auto"
                    src="@/assets/images/magnifyingGlass.svg"
                    alt="magnifyingGlassIcon"
                  />
                </button>
                <button
                  @click="resetFilterDiscounts"
                  class="group w-full rounded-md bg-[#eaeaeb] dark:bg-shadowy-steel px-3 py-2.5"
                >
                  <img
                    class="group-hover:scale-105 duration-300 mx-auto"
                    src="@/assets/images/crossedMagnifyingGlass.svg"
                    alt="crossedMagnifyingGlassIcon"
                  />
                </button>
              </td>
            </tr>
            <tr
              v-for="discount in discounts"
              :key="discount.id"
              :class="{
                'grid grid-cols-[116px_repeat(2,_246px)_minmax(100px,_1fr)_64px_116px_78px_repeat(2,_minmax(124px,_1fr))_48px_repeat(3,_minmax(48px,_96px))] items-end gap-x-1 py-1': true,
                'bg-lush-green rounded-md': discount.just_added
              }"
            >
              <td
                :class="
                  discount.status === 0
                    ? 'grid grid-rows-[24px,_minmax(46px,_1fr)] grid-cols-[subgrid]'
                    : ''
                "
                class="self-center"
              >
                <span></span>
                <p class="self-center" :class="{ 'text-orange-500': discount.status === 0 }">
                  {{ discount.id }}
                </p>
              </td>
              <td
                :class="
                  discount.status === 0
                    ? 'grid grid-rows-[24px,_minmax(46px,_1fr)] grid-cols-[subgrid]'
                    : ''
                "
              >
                <p class="text-orange-500 text-base text-left self-end">
                  <span v-if="!filters.status.value"
                    >Created At {{ moment(discount.created_at).format('DD-MM-YYYY hh:mm') }}</span
                  >
                </p>
                <VueDatePicker
                  placeholder="Date / Time*"
                  class="rounded-md"
                  :style="{
                    '--dp-input-padding': '10px 12px',
                    '--dp-border-color': '#343739'
                  }"
                  :preview-format="dateFormat"
                  :clearable="false"
                  :format="dateFormat"
                  :dark="mode === 'dark'"
                  time-picker-inline
                  v-model="discount.start_date"
                  @update:model-value="updateDiscounts(discount)"
                  @internal-model-change="handleInternal"
                />
              </td>
              <td
                :class="
                  discount.status === 0
                    ? 'grid grid-rows-[24px,_minmax(46px,_1fr)] grid-cols-[subgrid]'
                    : ''
                "
              >
                <p class="text-orange-500 text-base text-left self-end">
                  <span v-if="discount.status === 0">Archived {{ discount.archived_at }}</span>
                </p>
                <VueDatePicker
                  :dark="mode === 'dark'"
                  placeholder="Date / Time*"
                  class="rounded-md"
                  :style="{
                    '--dp-input-padding': '10px 12px',
                    '--dp-border-color': '#343739'
                  }"
                  :preview-format="dateFormat"
                  :clearable="false"
                  :format="dateFormat"
                  time-picker-inline
                  v-model="discount.end_date"
                  @update:model-value="updateDiscounts(discount)"
                  @internal-model-change="handleInternal"
                />
              </td>
              <td
                :class="
                  discount.status === 0
                    ? 'grid grid-rows-[24px,_minmax(46px,_1fr)] grid-cols-[subgrid]'
                    : ''
                "
              >
                <span></span>
                <textarea
                  v-model="discount.description"
                  @input="updateDiscounts(discount)"
                  class="w-full h-full rounded-md bg-[#eaeaeb] dark:bg-shadowy-steel focus:border-transparent focus:ring-0 focus:outline-lush-green focus:outline-offset-0"
                  rows="1"
                ></textarea>
              </td>
              <td
                :class="
                  discount.status === 0
                    ? 'grid grid-rows-[24px,_minmax(46px,_1fr)] grid-cols-[subgrid]'
                    : ''
                "
              >
                <span></span>
                <select
                  v-model="discount.plus_minus"
                  @input="updateDiscounts(discount)"
                  class="w-full cursor-pointer appearance-none rounded-md bg-[#eaeaeb] dark:bg-shadowy-steel bg-[url(@/assets/images/arrow.svg)] bg-[right_12px_center] bg-no-repeat py-2.5 pl-3 pr-9 focus:border-transparent focus:ring-0 focus:outline-lush-green focus:outline-offset-0"
                >
                  <option value="plus">+</option>
                  <option value="minus">-</option>
                </select>
              </td>
              <td
                :class="
                  discount.status === 0
                    ? 'grid grid-rows-[24px,_minmax(46px,_1fr)] grid-cols-[subgrid]'
                    : ''
                "
              >
                <span></span>
                <select
                  v-model="discount.type"
                  @input="updateDiscounts(discount)"
                  class="w-full cursor-pointer appearance-none rounded-md bg-[#eaeaeb] dark:bg-shadowy-steel bg-[url(@/assets/images/arrow.svg)] bg-[right_12px_center] bg-no-repeat py-2.5 pl-3 pr-9 focus:border-transparent focus:ring-0 focus:outline-lush-green focus:outline-offset-0"
                >
                  <option value="0">%</option>
                  <option value="1">€</option>
                </select>
              </td>
              <td
                :class="
                  discount.status === 0
                    ? 'grid grid-rows-[24px,_minmax(46px,_1fr)] grid-cols-[subgrid]'
                    : ''
                "
              >
                <span></span>
                <input
                  class="w-full rounded-md bg-[#eaeaeb] dark:bg-shadowy-steel px-3 py-2.5 focus:border-transparent focus:ring-0 focus:outline-lush-green focus:outline-offset-0"
                  type="text"
                  @input="updateDiscounts(discount)"
                  v-model="discount.discount_value"
                />
              </td>
              <td
                :class="
                  discount.status === 0
                    ? 'grid grid-rows-[24px,_minmax(46px,_1fr)] grid-cols-[subgrid]'
                    : ''
                "
              >
                <span></span>
                <div class="multiselect relative uppercase">
                  <div
                    @click="showMultiselectOptions"
                    class="multiselectHeading cursor-pointer overflow-x-clip whitespace-nowrap rounded-md bg-[#eaeaeb] dark:bg-shadowy-steel py-2.5 pl-3 pr-9"
                  >
                    <span>{{ selectedAreas(discount.areas_array) }}</span>
                    <div
                      class="absolute right-0 top-[calc(50%-12px)] my-auto bg-[#eaeaeb] dark:bg-shadowy-steel px-3"
                    >
                      <img src="@/assets/images/arrow.svg" alt="arrowIcon" />
                    </div>
                  </div>
                  <div
                    class="options absolute left-0 top-[calc(100%_+_12px)] z-10 w-max min-w-full divide-y divide-midnight-ink rounded-md bg-[#eaeaeb] dark:bg-shadowy-steel hidden"
                  >
                    <label
                      :key="index"
                      v-for="(area, index) in areas"
                      class="flex cursor-pointer items-center gap-x-3 p-3"
                    >
                      <input
                        :id="'discount' + discount.id + '-area' + area.id"
                        @change="changeDiscountArea(area, discount)"
                        :checked="areaChecked(discount.areas_array, area.id)"
                        class="h-6 w-6 cursor-pointer appearance-none rounded-[5px] border border-lush-green bg-center bg-no-repeat checked:bg-[url(@/assets/images/tick.svg)] checked:bg-lush-green checked:bg-blend-difference focus:ring-lush-green focus:bg-blend-difference"
                        type="checkbox"
                      />
                      <span>{{ area.area_name }}</span>
                    </label>
                  </div>
                </div>
              </td>
              <td
                :class="
                  discount.status === 0
                    ? 'grid grid-rows-[24px,_minmax(46px,_1fr)] grid-cols-[subgrid]'
                    : ''
                "
              >
                <span></span>
                <div class="multiselect relative">
                  <div
                    @click="showMultiselectOptions"
                    class="multiselectHeading cursor-pointer overflow-x-clip whitespace-nowrap rounded-md bg-[#eaeaeb] dark:bg-shadowy-steel py-2.5 pl-3 pr-9"
                  >
                    <span>{{ selectedCars(discount.cars_array) }}</span>
                    <div
                      class="absolute right-0 top-[calc(50%-12px)] my-auto bg-[#eaeaeb] dark:bg-shadowy-steel px-3"
                    >
                      <img src="@/assets/images/arrow.svg" alt="arrowIcon" />
                    </div>
                  </div>
                  <div
                    class="options absolute left-0 top-[calc(100%_+_12px)] z-10 w-max min-w-full divide-y divide-midnight-ink rounded-md bg-[#eaeaeb] dark:bg-shadowy-steel hidden"
                  >
                    <label class="flex cursor-pointer items-center gap-x-3 p-3">
                      <input
                        :id="'select-all-cars' + discount.id"
                        @change="selectAllCarsInOddsTable(discount)"
                        :checked="areAllCarsSelectedInRow(discount)"
                        class="h-6 w-6 cursor-pointer appearance-none rounded-[5px] border border-lush-green bg-center bg-no-repeat checked:bg-[url(@/assets/images/tick.svg)] checked:bg-lush-green checked:bg-blend-difference focus:ring-lush-green focus:bg-blend-difference"
                        type="checkbox"
                      />
                      <span>Select All</span>
                    </label>
                    <label
                      :key="index"
                      v-for="(car, index) in cars"
                      class="flex cursor-pointer items-center gap-x-3 p-3"
                    >
                      <input
                        :id="'discount' + discount.id + '-car' + car.class_id"
                        @change="changeDiscountCar(car, discount)"
                        :checked="carChecked(discount.cars_array, car.class_id)"
                        class="h-6 w-6 cursor-pointer appearance-none rounded-[5px] border border-lush-green bg-center bg-no-repeat checked:bg-[url(@/assets/images/tick.svg)] checked:bg-lush-green checked:bg-blend-difference focus:ring-lush-green focus:bg-blend-difference"
                        type="checkbox"
                      />
                      <span>{{ car.class_name }}</span>
                    </label>
                  </div>
                </div>
              </td>
              <td
                :class="
                  discount.status === 0
                    ? 'grid grid-rows-[24px,_minmax(46px,_1fr)] grid-cols-[subgrid]'
                    : ''
                "
              >
                <span></span>
                <div
                  class="rounded-md bg-[#eaeaeb] dark:bg-shadowy-steel p-2.5 flex justify-center items-center cursor-pointer"
                >
                  <input
                    v-model="discount.priority"
                    @input="updateDiscounts(discount)"
                    class="h-6 w-6 appearance-none rounded-[5px] border border-lush-green bg-center bg-no-repeat duration-300 checked:bg-[url(@/assets/images/tick.svg)] checked:bg-lush-green checked:bg-blend-difference focus:ring-lush-green focus:bg-blend-difference"
                    type="checkbox"
                  />
                </div>
              </td>
              <td
                :class="
                  discount.status === 0
                    ? 'grid grid-rows-[24px,_minmax(46px,_1fr)] grid-cols-[subgrid]'
                    : ''
                "
              >
                <span></span>
                <button
                  v-if="checkPermission('discounts.delete')"
                  @click="showModal('removeDiscount', discount)"
                  class="group w-full h-full rounded-md bg-[#eaeaeb] dark:bg-shadowy-steel px-3 py-2.5"
                >
                  <img
                    class="group-hover:scale-105 duration-300 mx-auto"
                    src="@/assets/images/trash.svg"
                    alt="trashIcon"
                  />
                </button>
              </td>
              <td
                :class="
                  discount.status === 0
                    ? 'grid grid-rows-[24px,_minmax(46px,_1fr)] grid-cols-[subgrid]'
                    : ''
                "
              >
                <span></span>
                <button
                  v-if="checkPermission('discounts.duplicate')"
                  @click="duplicateDiscount(discount)"
                  class="group w-full h-full rounded-md bg-[#eaeaeb] dark:bg-shadowy-steel px-3 py-2.5"
                >
                  <img
                    class="group-hover:scale-105 duration-300 mx-auto"
                    src="@/assets/images/copy.svg"
                    alt="copyIcon"
                  />
                </button>
              </td>
              <td
                :class="
                  discount.status === 0
                    ? 'grid grid-rows-[24px,_minmax(46px,_1fr)] grid-cols-[subgrid]'
                    : ''
                "
              >
                <span></span>
                <button
                  v-if="checkPermission('discounts.archive')"
                  @click="showModal('archiveDiscount', discount)"
                  class="group w-full h-full rounded-md bg-[#eaeaeb] dark:bg-shadowy-steel px-3 py-2.5"
                >
                  <img
                    class="group-hover:scale-105 duration-300 mx-auto"
                    src="@/assets/images/folder.svg"
                    alt="folderIcon"
                  />
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>
    <section class="space-y-[18px] leading-[1.1]">
      <button
        v-if="!requestTime && checkPermission('discounts.edit')"
        @click="discountsSubmit"
        class="w-full rounded-full border border-lush-green bg-lush-green py-[22px] font-semibold text-midnight-ink duration-300 hover:bg-transparent dark:hover:text-white"
      >
        Save
      </button>
      <button
        v-if="requestTime"
        disabled
        class="w-full rounded-full border border-lush-green bg-transparent text-white py-[22px] font-semibold duration-300"
      >
        <LoadSpinner />
        {{ loadText }}
      </button>
      <fwb-pagination
        v-model="meta.current_page"
        :total-items="meta.total"
        :totalPages="meta.last_page"
        :perPage="meta.per_page"
        :showLabels="false"
        show-icons
        @click="paginationBarClicked"
      ></fwb-pagination>
    </section>
  </main>
</template>

<script setup>
import '@vuepic/vue-datepicker/dist/main.css'
import VueDatePicker from '@vuepic/vue-datepicker'
import { inject, onBeforeMount, onMounted, ref } from 'vue'
import { useDatePicker } from '@/compose/datePicker'
import { usehasClassInHierarchy } from '@/compose/hasClassInHierarchy'
import { collect } from 'collect.js'
import { useCarsStore } from '@/stores/cars'
import { useAreasStore } from '@/stores/areas'
import { storeToRefs } from 'pinia'
import LoadSpinner from '@/components/custom/LoadSpinner.vue'
import moment from 'moment'
import { useFetcher } from '@/compose/axios'
import { useUserStore } from '@/stores/user'
import DiscountModals from '@/components/modals/DiscountModals.vue'
import { useMainStore } from '@/stores/main'
import { FwbPagination } from 'flowbite-vue'
import InputMask from 'primevue/inputmask'

const mainStore = useMainStore()
const { mode } = storeToRefs(mainStore)

const { handleInternal, dateFormat } = useDatePicker()
const { hasClassInHierarchy } = usehasClassInHierarchy()

const { axiosInstance } = useFetcher({ baseUrl: import.meta.env.VITE_APP_API_URL })
const menuCollapsed = inject('menuCollapsed')
const discounts = ref(null)

const carsStore = useCarsStore()
const areasStore = useAreasStore()
const userStore = useUserStore()

const { areas } = storeToRefs(areasStore)
const { cars } = storeToRefs(carsStore)

const loadText = ref('Loading...')

const order = ref({
  start_date: 'asc',
  active_order: 'start_date'
})

const isShowModal = ref(false)
const modalData = ref({
  type: null,
  id: null
})
const showModal = (type, entity) => {
  isShowModal.value = true
  modalData.value.type = type
  modalData.value.entity = entity
}
const closeModal = () => {
  isShowModal.value = false
}

const changeDiscountArea = (area, discount) => {
  changeDiscountAreas(area, discount)
  updateDiscounts(discount)
}

const changeDiscountCar = (car, discount) => {
  updateCars(car, discount)
  updateDiscounts(discount)
}
const modalAction = (data) => {
  if (data.type === 'archiveDiscount') {
    archiveDiscount(data.entity)
  } else if (data.type === 'removeDiscount') {
    removeDiscount(data.entity)
  }
}

const selectAllCarsInOddsTable = (discount) => {
  if (areAllCarsSelectedInRow(discount)) {
    discount.cars_array = []
  } else {
    discount.cars_array = cars.value.map((car) => car.class_id)
  }
  updateDiscounts(discount)
}

const areAllCarsSelectedInRow = (discount) => {
  return cars.value.every((car) => carChecked(discount.cars_array, car.class_id))
}

// const areAllCarsSelected = computed(() => {
//   return discounts.value.every((discount) =>
//     cars.value.every((car) => carChecked(discount.cars_array, car.class_id))
//   )
// })

const updateCars = (car, discount) => {
  if (carChecked(discount.cars_array, car.class_id)) {
    carUncheck(car, discount)
  } else {
    carCheck(car, discount)
  }

  const selectAllCheckbox = document.getElementById('select-all-cars' + discount.id)
  if (selectAllCheckbox) {
    selectAllCheckbox.checked = areAllCarsSelectedInRow(discount)
  }
}

const checkPermission = (permission) => {
  return userStore.checkPermission(permission)
}

const checkNumber = (e) => {
  if (e.data !== '-') return true
  else e.preventDefault()
}

const resetFilterDiscounts = () => {
  let filtersDefaultValue = {
    progid: {
      value: null
    },
    start_date: {
      value: null
    },
    end_date: {
      value: null
    },
    description: {
      value: null
    },
    plus_minus: {
      value: null
    },
    priority: {
      value: null
    },
    discount_value: {
      value: null
    },
    areas: {
      areas_array: [],
      areas_opened: false
    },
    cars: {
      cars_array: []
    }
  }

  filters.value = { status: { value: filters.value.status.value }, ...filtersDefaultValue }
  requestTime.value = true
  axiosInstance
    .post('/admin/discounts/filter', { filters: filters.value, order: order.value })
    .then((response) => {
      discounts.value = response.data.data
      requestTime.value = false
    })
}

const filters = ref({
  progid: {
    value: null
  },
  status: {
    value: true
  },
  start_date: {
    value: null
  },
  end_date: {
    value: null
  },
  description: {
    value: null
  },
  plus_minus: {
    value: null
  },
  priority: {
    value: null
  },
  discount_value: {
    value: null
  },
  areas: {
    areas_array: [],
    areas_opened: false
  },
  cars: {
    cars_array: []
  }
})

const handleInputMask = (filterType, inputValue) => {
  const [day, month, rest] = inputValue.split('/')
  const isDateValid = () => new Date(`${month}/${day}/${rest}`).toString() != 'Invalid Date'
  if (isDateValid()) {
    filters.value[filterType].value = moment(inputValue, 'DD/MM/YYYY HH:mm').format(
      'YYYY-MM-DDTHH:mm:ss.SSS[Z]'
    )
  }
}

const meta = ref({
  current_page: 1,
  from: null,
  last_page: null,
  path: null,
  per_page: null,
  to: null,
  total: null
})

// const discountsRepository = computed(() => {
//   if (discounts.value) {
//     // if (!showArchived.value) {
//     //   return discounts.value.filter((e) => e.status !== 0)
//     // }
//     return discounts.value
//   }
//
//   return null
// })

const changeShowArchived = () => {
  filters.value.status.value = !filters.value.status.value
  requestTime.value = true
  filterDiscounts()
}

const showMultiselectOptions = (event) => {
  const currentMultiselectOptions = event.currentTarget.nextElementSibling

  document.querySelectorAll('.multiselect .options').forEach((multiselectOptions) => {
    if (multiselectOptions !== currentMultiselectOptions) multiselectOptions.classList.add('hidden')
  })

  currentMultiselectOptions.classList.toggle('hidden')
}
const hideAllMultiselectOptions = (event) => {
  if (!hasClassInHierarchy(event.target, 'multiselect')) {
    document.querySelectorAll('.multiselect .options').forEach((multiselectOptions) => {
      multiselectOptions.classList.add('hidden')
    })
  }
}

const archiveDiscount = async (discount) => {
  requestTime.value = true
  loadText.value = 'Archiving Discount...'
  axiosInstance.post('/admin/discounts/archive', { id: discount.id }).then((response) => {
    if (response.data.status === 'success') {
      discount.status = 1
      discount.archived_at = moment().add('hours', 1).format()
      axiosInstance
        .post('/admin/discounts/filter', { filters: filters.value, order: order.value })
        .then((response) => {
          discounts.value = response.data.data
          requestTime.value = false
        })
    }
  })
}
//!
const addDiscount = () => {
  requestTime.value = true
  loadText.value = 'Adding Dicsount...'

  axiosInstance
    .post('/admin/discounts/create', {
      type: 0,
      description: 'New Discount',
      discount_value: 0,
      start_date: moment().endOf('hour').add(1, 'seconds').format('Y-M-D H:mm:ss'),
      end_date: moment().endOf('day').format('Y-M-D H:mm:ss'),
      plus_minus: 'minus',
      priority: 0,
      status: 1,
      areas_array: [],
      cars_array: []
    })
    .then((response) => {
      response.data.data.just_added = 1
      discounts.value.unshift(response.data.data)
      requestTime.value = false
    })
}
const duplicateDiscount = (discount) => {
  requestTime.value = true
  loadText.value = 'Duplicating discount...'
  const collectDiscount = collect(discount)
  const collectAttributes = collectDiscount.except(['id'])

  axiosInstance.post('/admin/discounts/create', { ...collectAttributes.items }).then((response) => {
    discounts.value.unshift(response.data.data)
    requestTime.value = false
  })
}
const removeDiscount = (discount) => {
  requestTime.value = true
  loadText.value = 'Removing Discount...'
  axiosInstance.post('/admin/discounts/delete', { id: discount.id }).then((response) => {
    if (response.data.status === 'success') {
      discounts.value = discounts.value.filter((e) => e.id !== discount.id)
      requestTime.value = false
    }
  })
}

const updatedDiscountsList = ref([])

const updateDiscounts = (discount) => {
  updatedDiscountsList.value.find((item) => item.id === discount.id) ||
    updatedDiscountsList.value.push(discount)
}

const changeDiscountAreas = (area, discount) => {
  if (areaChecked(discount.areas_array, area.id)) {
    areaUncheck(area, discount)
  } else {
    areaCheck(area, discount)
  }
}

const changeDiscountCars = (car, discount) => {
  if (carChecked(discount.cars_array, car.class_id)) {
    carUncheck(car, discount)
    console.log('UnTrusteed')
  } else {
    carCheck(car, discount)
    console.log('Trusteed')
  }
}

const selectedAreas = (areasSelected) => {
  const tempObject = []

  areasSelected.forEach((e) => {
    let areaTemp = collect(areas.value).where('id', Number(e)).first()
    if (areaTemp) {
      tempObject.push(areaTemp.area_short_name)
    }
  })

  if (tempObject.length === areas.value?.length) {
    return 'All'
  }

  if (tempObject.length === 0) {
    return 'None'
  }

  return tempObject.join(', ')
}

const selectedCars = (carsSelected) => {
  const tempObject = []

  carsSelected.forEach((e) => {
    let carTemp = collect(cars.value).where('class_id', Number(e)).first()
    tempObject.push(carTemp.short_name)
  })

  if (tempObject.length === cars.value?.length) {
    return 'All'
  }

  if (tempObject.length === 0) {
    return 'None'
  }

  return tempObject.join(', ')
}

const carChecked = (discountCars, carId) => {
  return discountCars.includes(carId)
}

const carCheck = (car, discount) => {
  const tempArray = discount.cars_array
  tempArray.push(car.class_id)
  discount.cars_array = tempArray
}

const carUncheck = (car, discount) => {
  const tempArray = discount.cars_array
  discount.cars_array = tempArray.filter((e) => e !== car.class_id)
}

const areaChecked = (discountAreas, areaId) => {
  return discountAreas.includes(areaId)
}

const areaCheck = (area, discount) => {
  const tempArray = discount.areas_array
  tempArray.push(area.id)
  discount.areas_array = tempArray
}

const areaUncheck = (area, discount) => {
  const tempArray = discount.areas_array
  discount.areas_array = tempArray.filter((e) => e !== area.id)
}

const requestTime = ref(false)

const discountsSubmit = () => {
  requestTime.value = true
  loadText.value = 'Saving Coefficients...'

  const newCollect = collect(discounts.value)
  newCollect.transform((item) => {
    if (typeof item.end_date === 'object') {
      item.end_date = moment(item.end_date).format('YYYY-MM-DD H:mm:ss')
    }
    if (typeof item.start_date === 'object') {
      item.start_date = moment(item.start_date).format('YYYY-MM-DD H:mm:ss')
    }
    return item
  })

  discounts.value = newCollect.toArray()

  axiosInstance.post('/admin/discounts', updatedDiscountsList.value).then(() => {
    requestTime.value = false
  })
}
const paginationBarClicked = async () => {
  await filterDiscounts()
}
const filterDiscounts = async () => {
  requestTime.value = true
  loadText.value = 'Filtering Coefficients...'
  let filterData = { filters: filters.value, order: order.value }
  filterData.page = meta.value.current_page
  filterData.per_page = 10

  axiosInstance.post('/admin/discounts/filter', filterData).then((response) => {
    const data = response.data
    discounts.value = response.data.data

    meta.value.current_page = data.meta.current_page
    meta.value.from = data.meta.from
    meta.value.last_page = data.meta.last_page
    meta.value.per_page = data.meta.per_page
    meta.value.to = data.meta.to
    meta.value.total = data.meta.total
    meta.value.path = data.meta.path
    requestTime.value = false
  })
}

const setOrder = (column) => {
  order.value[column] = order.value[column] === 'asc' ? 'desc' : 'asc'
  order.value['active_order'] = column

  filterDiscounts()
}

// const orderDiscounts = () => {
//   axios.post('/admin/discounts/filt', order.value).then((response) => {
//     discounts.value = response.data
//   })
// }

onBeforeMount(async () => {
  requestTime.value = true
  loadText.value = 'Loading...'
  await areasStore.loadData()
  await filterDiscounts()
})

onMounted(() => {})

window.addEventListener('click', hideAllMultiselectOptions)
</script>

<style scoped>
.dp__theme_light {
  --dp-background-color: #eaeaeb;
}
</style>
